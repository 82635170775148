.welcome-section {
  /* border: 2px dotted black; */
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 0 2rem;
}

.welcome-title {
  font-family: Pinar-Regular, sans-serif;
  direction: rtl;
  text-align: right;
  align-self: flex-end;
  font-size: 44px;
  color: var(--headline);
  margin-right: 2rem;
}

@media (max-width: 768px) {
  /* Media query for mobile */
  .welcome-title {
  font-size: 24px;
  margin-right: 0rem;
}
}
.welcome-content {
  direction: rtl;
  text-align: justify;
  font-family: Vazirmatn-Regular, sans-serif;
  font-size: 30px;
}

/* .welcome-content-span {
  font-family: TYPOKAR, sans-serif;
} */

@media (max-width: 1024px) {
  /* Media query for mobile */
  .welcome-content {
  direction: rtl;
  text-align: justify;
  font-family: Vazirmatn-Regular, sans-serif;
  font-size: 26px;
}
}

@media (max-width: 957px) {
  /* Media query for mobile */
  .welcome-content {
  direction: rtl;
  text-align: justify;
  font-family: Vazirmatn-Regular, sans-serif;
  font-size: 30px;
}
}

@media (max-width: 820px) {
  /* Media query for mobile */
  .welcome-content {
  direction: rtl;
  text-align: justify;
  font-family: Vazirmatn-Regular, sans-serif;
  font-size: 30px;
}
}

@media (max-width: 768px) {
  /* Media query for mobile */
  .welcome-content {
  direction: rtl;
  text-align: justify;
  font-family: Vazirmatn-Regular, sans-serif;
  font-size: 30px;
}
}
@media (max-width: 440px) {
  /* Media query for mobile */
  .welcome-content {
  direction: rtl;
  text-align: justify;
  font-family: Vazirmatn-Regular, sans-serif;
  font-size: 16px;
}
}

@media (max-width: 420px) {
  /* Media query for mobile */
  .welcome-content {
  direction: rtl;
  text-align: justify;
  font-family: Vazirmatn-Regular, sans-serif;
  font-size: 16px;
}
}

/* Icons */
.socials-welcome {
  display: flex;
  gap: 4px;
  margin-top: 15px;
}

/* social-icons.css */
.social-icon-welcome {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 10px;
}

@media (max-width: 1024px) {
  /* Media query for mobile */
  .social-icon-welcome {
  display: none;
}
}

.social-icon-welcome.twitter-icon-welcome {
  background-color: rgba(251, 251, 251, 0.815);
  color: #1da1f2;
  font-size: 70px;
}

.social-icon-welcome.twitter-icon-welcome:hover,
.social-icon-welcome.twitter-icon-welcome:focus {
  color: #5b90b1;
}

.social-icon-welcome.youtube-icon-welcome {
  background-color: rgba(251, 251, 251, 0.815);
  color: rgb(163, 24, 24);
  font-size: 70px;
}

.social-icon-welcome.youtube-icon-welcome:hover,
.social-icon-welcome.youtube-icon-welcome:focus {
  color: rgb(98, 14, 14);
}

.social-icon-welcome.instagram-icon-welcome {
  background-color: rgba(251, 251, 251, 0.815);
  color: #e4405f;
  font-size: 70px;
}

.social-icon-welcome.instagram-icon-welcome:hover,
.social-icon-welcome.instagram-icon-welcome:focus {
  color: #b7314a;
}

.social-icon-welcome.telegram-icon-welcome {
  background-color: rgba(251, 251, 251, 0.815);
  color: #40a0e4;
  font-size: 70px;
}

.social-icon-welcome.telegram-icon-welcome:hover,
.social-icon-welcome.telegram-icon-welcome:focus {
  color: #2b70a2;
}