/* Modal.css */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.75);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.9px);
  -webkit-backdrop-filter: blur(7.9px);
}

.modal {
  padding: 40px;
  border-radius: 8px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-img-container{
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-image{
  width: 100%;
  object-fit: cover;
}
.close-btn {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.close-btn:hover {
  background-color: #555;
}

.modal-head {
  font-weight: bold;
  color: white;
  font-size: 44px;
}

.modal-text {
  font-family: Pinar-Regular, sans-serif;
  color: white;
  font-size: 44px;
}

.socials-modal {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

@media (max-width: 430px) {
  /* Media query for mobile */
  .modal-head {
    font-size: 20px;
  }

  .modal-text {
    font-size: 20px;
  }
}

.start-game-modal {
position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.75);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.9px);
  -webkit-backdrop-filter: blur(7.9px);
}

.modal-game-text {
  direction: rtl;
  font-size: 44px;
  font-family: Vazirmatn-Regular, sans-serif;
  color: white;
}

.start-btn {
  background-color: #333;
  border: none;
  cursor: pointer;
  padding: 10px 15px;
  border-radius: 15px;
  transition: all .4s ease-in-out;
}

.start-btn:hover,
.start-btn:focus {
  background-color: #6a6969;
}

@media (max-width: 430px) {
  /* Media query for mobile */
  .modal-game-text {
  direction: rtl;
  font-size: 24px;
  font-family: Vazirmatn-Regular, sans-serif;
  color: white;
}
}