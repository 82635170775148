.blueguys-card-hero-container {
  width: 200px;
  height: 300px;
  position: relative;
}

.blueguys-card-hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.book-tag {
  position: absolute;
  background-color: rgba(169, 168, 168, 0.65);
  z-index: 4;
  top: 10px;
  width: 50%;
  direction: rtl;
  color: var(--black);
  text-align: center;
}