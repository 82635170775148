.not-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.not-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.not-head {
  font-size: 200px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.not-text {
  direction: rtl;
  font-family: Pinar-DS4-Bold, sans-serif;
  font-size: 50px;
}

@media (max-width: 1024px) {
  /* Media query for mobile */
  .not-head {
    font-size: 200px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }
  .not-image {
    width: 500px;
  }
  .not-text {
    direction: rtl;
    font-family: Pinar-DS4-Bold, sans-serif;
    font-size: 50px;
  }
  .not-section {
  min-height: calc(100vh - 380px);
}
}

@media (max-width: 820px) {
  /* Media query for mobile */
  .not-head {
    font-size: 140px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }
  .not-image {
    width: 430px;
  }
  .not-text {
    direction: rtl;
    font-family: Pinar-DS4-Bold, sans-serif;
    font-size: 50px;
  }
  .not-section {
  min-height: calc(100vh - 300px);
}
}


@media (max-width: 440px) {
  /* Media query for mobile */
  .not-head {
    font-size: 80px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }
  .not-image {
    width: 200px;
  }
  .not-text {
    direction: rtl;
    font-family: Pinar-DS4-Bold, sans-serif;
    font-size: 20px;
  }
  .not-section {
  min-height: calc(100vh - 250px);
}
}

@media (max-width: 420px) {
  /* Media query for mobile */
  .book-hero-section {
    grid-template-columns: 1fr; /* One column for mobile */
    grid-template-rows: 720px 1fr; /* Two rows for mobile */
    gap: 0rem;
  }
}
