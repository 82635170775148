footer {
  background-image: url(../../assets/footer.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  width: 100%;
  height: 350px;
  position: relative;
  
}
@media (max-width: 1024px) {
  /* Media query for mobile */
  footer {
    height: 250px;
  }
}
@media (max-width: 957px) {
  /* Media query for mobile */
  footer {
    height: 200px;
  }
}

@media (max-width: 768px) {
  /* Media query for mobile */
  footer {
    height: 200px;
  }
}

@media (max-width: 440px) {
  /* Media query for mobile */
  footer {
    background-image: none;
    background-color: var(--headline);
    width: 100%;
    height: 150px;
    position: relative;
  }
}

@media (max-width: 420px) {
  /* Media query for mobile */
  footer {
    background-image: none;
    background-color: var(--headline);
    width: 100%;
    height: 150px;
    position: relative;
  }
}

.socials-footer {
  position: absolute;
  display: flex;
  gap: 4px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
}

/* social-icons.css */
.social-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 10px;
}

.social-icon.twitter-icon {
  background-color: rgba(169, 168, 168, 0.513);
  color: #1da1f2;
  font-size: 50px;
}

.social-icon.twitter-icon:hover,
.social-icon.twitter-icon:focus {
  color: #5b90b1;
}

.social-icon.youtube-icon {
  background-color: rgba(169, 168, 168, 0.513);
  color: rgb(163, 24, 24);
  font-size: 50px;
}

.social-icon.youtube-icon:hover,
.social-icon.youtube-icon:focus {
  color: rgb(213, 33, 33);
}

.social-icon.instagram-icon {
  background-color: rgba(169, 168, 168, 0.513);
  color: #e4405f;
  font-size: 50px;
}

.social-icon.instagram-icon:hover,
.social-icon.instagram-icon:focus {
  color: #b7314a;
}

.social-icon.telegram-icon {
  background-color: rgba(169, 168, 168, 0.513);
  color: #40a0e4;
  font-size: 50px;
}

.social-icon.telegram-icon:hover,
.social-icon.telegram-icon:focus {
  color: #2b70a2;
}

.copy-footer {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  color: var(--white);
  direction: rtl;
}

@media (max-width: 1024px) {
  /* Media query for mobile */
  .book-hero-section {
    grid-template-columns: 1fr; /* One column for mobile */
    grid-template-rows: 700px 1fr; /* Two rows for mobile */
    gap: 3rem;
  }
}

@media (max-width: 957px) {
  /* Media query for mobile */
  .socials-footer {
    display: flex;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 10%);
  }

  .copy-footer {
    font-size: 11px;
  }
}

@media (max-width: 768px) {
  /* Media query for mobile */
  .socials-footer {
    display: flex;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 20%);
  }

  .copy-footer {
    font-size: 10px;
  }
}

@media (max-width: 440px) {
  /* Media query for mobile */
  .socials-footer {
    display: flex;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .copy-footer {
    font-size: 6px;
  }
}

@media (max-width: 420px) {
  /* Media query for mobile */
  .socials-footer {
    display: flex;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .copy-footer {
    font-size: 6px;
  }
}
