.content-section {
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='160' height='160' viewBox='0 0 200 200'%3E%3Cpolygon fill='%23DCEFFA' points='100 0 0 100 100 100 100 200 200 100 200 0'/%3E%3C/svg%3E");
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px;
}

.content-container-one {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* border: 2px solid black; */
  padding: 2rem;
  gap: 3rem;
  direction: rtl;
}

.content-title {
    color: var( --headline);
    font-family: Pinar-DS4-Bold, sans-serif;
}

.content-container-two {
  /* border: 2px solid blue; */
  display: flex;
  justify-content: center;

}

.content-details {
  display: flex;
  direction: rtl;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  gap: 2rem;
  /* background: rgba(255, 255, 255, 0.14);
  border-radius: 25px;
  backdrop-filter: blur(5.5px);
  -webkit-backdrop-filter: blur(5.5px); */
  /* border: 2px solid orange; */
  /* width: 50%; */
}
.flex {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4rem;
}


@media (max-width: 957px) {
  /* Media query for mobile */
  .flex {
    flex-direction: column-reverse;
    gap: 2rem;
}
}


.content-detail-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 7px;
    /* border: 1px solid red; */
}
.content-detail {
    display: flex;
    align-items: center;
    gap: 2rem;
    /* justify-content: center; */
    /* width: 50%; */
    /* border: 2px solid blue; */
    width: 100%;
    font-family:Pinar-Regular, sans-serif;
     color: var( --headline);
}

.content-detail-content {
  color: black;
  font-family: Vazirmatn-Regular,sans-serif;
}

.content-detail-summary {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    /* border: 2px solid green; */
    font-family:Pinar-Regular, sans-serif;
     color: var( --headline);
}

@media (max-width: 768px) {
  /* Media query for mobile */
  .content-detail-summary {
    width: 100%;
    /* border: 2px solid green; */
}
}

.content-detail-title-summary {
    align-self: flex-start;
}
.content-detail-content-summary {
    text-align: justify;
    align-self: center;
    color: black;
  font-family: Vazirmatn-Regular,sans-serif;
}

.pic-gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-template-rows: repeat(auto-fill, minmax(171px, 1fr));
  grid-gap: 12px;
}

.navigation-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.prev-button,
.next-button {
  background-color: var(--headline);
  color: white;
  padding: 5px 10px;
  border-radius: 25px;
  font-family:Vazirmatn-Regular , sans-serif ;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .2s all ease-in-out;
}

.prev-button:hover,
.next-button:hover{
  background-color: var(--sub-headline);
}