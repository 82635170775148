.up-container {
  position: fixed;
  bottom: 10px;
  left: 5px;
  width: 50px;
  cursor: pointer;
  z-index: 300;
  transform: rotate(-60deg) translate(-100px, -100px);
  transition: transform 1s;
}

.broom {
  width: 150px;
  transition: transform 1s; /* Add a smooth transition for the transform property */
}

@media (max-width: 430px) {
  /* Media query for mobile */
  .broom {
  width: 90px;
}
}

.up-container.show {
    transform: rotate(-60deg) translate(0,0);
}

.fly {
  animation: flying 2s ease-in-out; /* Define the flying animation */
}

@keyframes flying {
  0% {
    transform:translate(0, 0) rotate(-60deg);
  }
  
  100% {
    transform: translate(0, -100vh) rotate(-30deg); /* Fly the broom upwards (the height of the viewport) */
  }
}
