* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --white: hsl(0, 0%, 100%);
  --gray10: hsl(0, 0%, 10%);
  --gray20: hsl(0, 0%, 20%);
  --gray30: hsl(0, 0%, 30%);
  --gray40: hsl(0, 0%, 40%);
  --gray50: hsl(0, 0%, 50%);
  --gray60: hsl(0, 0%, 60%);
  --gray70: hsl(0, 0%, 70%);
  --gray80: hsl(0, 0%, 80%);
  --gray90: hsl(0, 0%, 90%);
  --black: hsl(0, 0%, 0%);
  --blue10: #002366;
  --blue20: #1034a6;
  --blue30: #192bc2;
  --blue40: #15608d;
  --blue50: #1e90ff;
  --blue60: #73c3fb;
  --blue70: #87ceeb;
  --background: #d8eefe;
  --headline: #094067;
  --sub-headline: #5f6c7b;
  --card-background: #fffffe;
  --card-heading: #094067;
  --card-paragraph: #5f6c7b;
  --stroke: #094067;
  --main: #fffffe;
  --highlight: #3da9fc;
  --secondary: #90b4ce;
  --tertiary: #ef4565;
}

.red {
  background-color: #ef45648b;
}

.blue {
  background-color: #1e8fff95;
}

.green {
  background-color: #10a679a4;
}

.yellow {
  background-color: #efdb23b8;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
main {
  overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}

@font-face {
  font-family: "Bahman-1"; /*Can be any text*/
  src: local("Bahman-1"), url("./fonts/Bahman-1.ttf") format("truetype");
}

@font-face {
  font-family: "Bahman-2"; /*Can be any text*/
  src: local("Bahman-2"), url("./fonts/Bahman-2.ttf") format("truetype");
}

@font-face {
  font-family: "Pinar-Regular"; /*Can be any text*/
  src: local("Pinar-Regular"),
    url("./fonts/Pinar-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Pinar-DS4-Bold"; /*Can be any text*/
  src: local("Pinar-DS4-Bold"),
    url("./fonts/Pinar-DS4-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Vazirmatn-Regular"; /*Can be any text*/
  src: local("Vazirmatn-Regular"),
    url("./fonts/Vazirmatn-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "W-kelas-aval"; /*Can be any text*/
  src: local("W-kelas-aval"), url("./fonts/W-kelas-aval.ttf") format("truetype");
}

@font-face {
  font-family: "TYPOKAR"; /*Can be any text*/
  src: local("TYPOKAR"), url("./fonts/TYPOKAR.ttf") format("truetype");
}
