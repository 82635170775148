.blueguys-hero-section {
  background-color: var(--white);
}

.blueguys-hero-title {
  direction: rtl;
  font-family: Pinar-Regular, sans-serif;
  font-size: 44px;
  color: var(--headline);
  margin-right: 4rem;
}

@media (max-width: 768px) {
  /* Media query for mobile */
  .blueguys-hero-title {
  font-size: 24px;
  margin-right: 2rem;
}
}
