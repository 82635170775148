.button-accordion {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.show-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  width: 200px;
  font-family: Pinar-DS4-Bold, sans-serif;
  transition: all .3s ease-in-out;
}

.show-button:hover,
.show-button:focus {
  background-color: #0451a3;
}

.button-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  direction: rtl;
}

.accordion-button {
  margin: 5px;
  padding: 8px 16px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}

.accordion-button:hover,
.accordion-button:focus {
  background-color: #ddd;
}

.no-homework-message {
  direction: rtl;
  font-family: Pinar-Regular, sans-serif;
  color: var(--headline);
  font-size: 33px;
}
