.overlay-book {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 400;
}

.overlay-content-book {
  position: relative;
  overflow: auto;
}

.close-button-book {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 100;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 10px 20px;
}
.close-button-book:hover {
  background-color: #555;
}

.overlay-book-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 401;
  font-family: Pinar-DS4-Bold, sans-serif;
  color: red;
  font-size: 40px;
  direction: rtl;
  text-align: center;
}

.overlay-book-text-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -250%);
  z-index: 401;
  font-family: Pinar-DS4-Bold, sans-serif;
  color: red;
  font-size: 30px;
  direction: rtl;
  text-align: center;
}

.iframe-container {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.gif-image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
