/* .input-text {
     background-color: #d0d0d0bf;
  border-radius: 12px;
  border: 0;
  box-sizing: border-box;
  color: var(--sub-headline);
  font-size: 18px;
  height: 400px;
  outline: 0;
  padding: 4px 20px 0;
  width: 100%;
} */

.form-submit-result{
  direction: rtl;
}