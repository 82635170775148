.banner {
    height: 30px;
    background-color: #b2071d;
    /* direction: rtl; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.banner-title{
    display: flex;
    align-items: center;
    gap: .5rem;
    color: white;
}
.youtube-logo{
    color: white;
    font-size: 30px;
    padding-top: .3rem;
}

.youtube{
    background-color: rgba(239, 238, 238, 0.485);
    color: white;
    padding: 0rem 1rem;
    border-radius: 15px;
}

.youtube:hover,
.youtube:focus {
    background-color: rgb(213, 33, 33);
}

.youtube-logo:hover,
.youtube-logo:focus{
    color: rgb(213, 33, 33);
}
