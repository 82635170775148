/* BabyYoda.css */

.baby-container {
  position: fixed;
  z-index: 10;
  right: 10px;
  bottom: 10px;
  opacity: 0; /* Start with opacity 0 to make it invisible */
  transform: translateY(100%); /* Move it below the screen */
  transition: opacity 0.5s ease, transform 0.5s ease; /* Add transitions for smooth animation */
  cursor: pointer;
}

.baby-container.visible {
  opacity: 1; /* When the component is visible, set opacity to 1 */
  transform: translateY(0); /* Move it to its original position */
}

.baby {
  width: 100px;
  transition: transform 0.2s ease; /* Add transition for smooth hover animation */
}

.baby:hover {
  /* Add the animation properties on hover */
  transform: rotate(10deg); /* Adjust the rotation angle as needed */
}

@media (max-width: 430px) {
  /* Media query for mobile */
  .baby {
    width: 70px;
  }
}
