header {
  width: 100%;
  background-color: var(--headline);
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
}

.list {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
  font-family: Bahman-1, sans-serif;
  position: relative;
}

.items a{
  color: var(--white);
  font-size: 30px;
  transition: .3s all ease-in-out;
  text-align: center;
  position: relative;
  top: 5px;
}

@media (max-width: 820px) {
  .items a{
  font-size: 20px; 
}
}

.items a:focus,
.items a:hover {
  color: var(--tertiary);
}

.logo {
  width: 100px;
}
@media (max-width: 820px) {
 .logo {
  width: 70px;
}
}

.navigation-link.active {
color: var(--tertiary);
}
